// If you comment out code below, bootstrap will use red as primary color
// and btn-primary will become red

// $primary: red;

// @import "~bootstrap/scss/bootstrap.scss";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html {
  font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", "Source Han Sans CN", sans-serif;
  font-size: 15px;
}

header {
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);;
}

.img-item {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  
  .img-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.img-item::before {
  content: '';
  display: block;
  padding-top: 62.5%;
}

.search-btns {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

[hidden] {
  display: none !important;
}

.doropdown {
  position: relative;
}

.dropdown::before {
  position: absolute;
  right: -16px;
  content: "";
  height:71.25px;
  width: 14px;
  opacity: 0.5;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMzAwIiBoZWlnaHQ9IjMwMCI+PHBhdGggZD0iTTUxMiA1NjIuMDA1bDIxMS4yLTIxMS4yIDYwLjMzIDYwLjMzMUw1MTIgNjgyLjY2NmwtMjcxLjUzLTI3MS41MyA2MC4zMy02MC4zM3oiLz48L3N2Zz4=");
  background-size: 20px;
  background-position: 50%;
  background-repeat: no-repeat;
  transition: transform .3s;
}

@media screen and (width < 1024px) {
  .dropdown::before {
    height:65.69px;
    width: 10px;
    right: -12px;
  }
}

.dropdown:hover::before {
  transform: rotate(-180deg);
}

.navigation-mobile {
  position: absolute;
  inset: 0;
  background-color: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 40px;
}

.navigation-mobile-toggle {
  background-color: transparent;
  border: none;
  margin: 0 0 0 auto;
}

.navigation-mobile-toggle[aria-expanded='true'] {
  position: absolute;
  z-index: 15;
  top: 30px;
  right: 20px;
}

@media screen and (width >=1280px) {
  .navigation-mobile {
    display: none;
  }
}

.navigation-mobile-toggle[aria-expanded='true'] .navigation-toggle-icon-bar {
  opacity: 0;
}

.navigation-mobile-toggle[aria-expanded='true'] .navigation-toggle-icon-bar:first-of-type {
  transform: translateY(10px) rotate(45deg);
  opacity: 1;
}

.navigation-mobile-toggle[aria-expanded='true'] .navigation-toggle-icon-bar:last-child {
  transform: translateY(-10px) rotate(-45deg);
  opacity: 1;
}

.blue-bar {
  position: relative;
  line-height: 1;
  padding-left: 12px;

  &::before {
    position: absolute;
    left: 0;
    content: '';
    height: 100%;
    width: 3px;

    @apply bg-blue-400;
  }
}

.triangle {
  position: relative;
  padding-left: 8px;

  &::before {
    position: absolute;
    left: -8px;
    top: 7.5px;
    content: "";
    width: 0;
    height: 0;
    line-height: 0;
    border: 4px solid transparent;
    border-left: 5px solid #ccc
  }
}


.htmx-request img {
  display: block;
}

.tag-btn-animation {
  background: linear-gradient(to left, rgb(243 244 246) 50%, rgb(96 165 250) 50%) right;
  background-size: 200%;
  transition: .3s ease-out;
}

.tag-btn-animation .tag-name {
  transition: .3s cubic-bezier(0, 1, 1, 1)
}

.tag-btn-animation:hover {
  background-position: left;

  .tag-name{
    color: white;
  }
}


.my-divider {
  width: 100%;
  margin: 12px 0;
  height: 1px;
  background-color: #f8f8fa;
}

.border-bottom {
  border-bottom: 1px solid #f8f8fa;
}

// svg fill transition
.icon path {
  transition: fill .3s cubic-bezier(0.4, 0, 0.2, 1);
}

.paginator-active:hover path {
  fill: white;
}

#wechat .icon:hover path {
  fill: #1AAD19
}

#search-open .icon:hover path {
  fill: #4283c4
}

#search-open .icon:hover {
  cursor: pointer;
}

#weibo .icon:hover path {
  fill: #c82a29
}

#link .icon:hover path {
  @apply fill-blue-400;
}


// override prose 
.prose {
  max-width: 100%;
}

// blog-sibling-transition
.blog-sibling-transition {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    transition: background-color 0.5s ease;
    inset: 0;
    background-color: rgb(0 0 0 / 40%);
  }

  &:hover::before {
    background-color: rgb(0 0 0 / 70%);
  }
}

// qa 
.qa-question {
  display: block;
  padding-left: 16px;

  &::before {
    display: inline-block;
    width: 0;
    height: 0;
    position: absolute;
    transition: all 0.2s;
    top: 8px;
    left: 0;
    font-size: 0;
    line-height: 0;
    border: 4px solid transparent;
    border-left: 6px solid #ccc;
    content: "";
    vertical-align: top;
  }

  &:hover::before {
    border-left: 6px solid rgb(96 165 250);
  }
}

.paginator {
  position: relative;

  &::before {
    content: "";
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    margin-left: 0.5rem;
    display: block;
    height: 0.7rem;
    width: 0.7rem;
    rotate: 45deg;
    opacity: 0.4;
    border-top: 2px solid white;
    border-right: 2px solid white;
    background-color: transparent;
  }

  &.left::before {
    rotate: -135deg;
    margin-left: 0.8rem;
  }
}

// post image
.cover-image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.card-hover {
  box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
  transition: all 300ms ease-out;

  &:hover {
    box-shadow: rgba(100 100 111 / 20%) 0 7px 29px 0;
    transform: translateY(-4px);
  }
}

.sidebar-fixed {
  position: fixed;
  bottom: 10px;
  left: 50%;
  margin-left: 224px;
}

.sidebar-fixed-top {
  top: 100px;
}

.sidebar-bottom-10 {
  bottom: 104px;
}

@media screen and ( width >= 1280px) {
  .sidebar-fixed {
    position: fixed;
    left: 50%;
    margin-left: 276px;
  }
}


@media screen and ( width >= 1120px) {
  .container-grid {
    display: grid;
    grid-template-columns: 1fr 300px; 
    gap: 20px; 
  }
}


.container-grid {
  display: block;
}

@media screen and ( width >= 1120px) {
  .container-grid {
    display: grid;
    grid-template-columns: 1fr 300px; 
    gap: 20px; 
  }
}

.sidebar {
  float: right;


}

.clear-fix {
  clear: both;

  &::after {
    content: '';
    clear: both;
    display: table;
  }
}

.htmx-indicator{
  opacity: 0;
  margin-top: 0;
  transition: opacity 500ms ease-in;
}

.htmx-request .htmx-indicator{
  opacity: 1;
  margin-top: 16px;
}

.htmx-request.htmx-indicator{
  opacity:1;
  margin-top: 16px;
}